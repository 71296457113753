/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const GoogleAnalytics = ({ sliderSettings }) => {
  const { screen1, screen2 } = useStaticQuery(graphql`
    query {
      screen1: file(relativePath: { eq: "help/google_analytics/ga_integration_2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "help/google_analytics/ga_integration_3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: screen2.childImageSharp.fluid,
      alt: 'Google Analytics Integration',
      title: 'Integrate Google Analytics with LiveSession',
    },
    {
      img: screen1.childImageSharp.fluid,
      alt: 'Google Analytics Integration',
      title: 'Integrate Google Analytics with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)}>
        {photos.map(({ img, alt, title }) => (
          <div key={img}>
            <Img fluid={img} alt={alt} title={title} />
          </div>
        ))}
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Google Analytics is the market leader when it comes to digital analytics software. It’s a
          free yet comprehensive tool to understand your customers. You can use it to get a
          real-time view of user activity, learn more about your audience and their behavior,
          optimize conversion and track acquisition channels. The tool can be connected with other
          Google products, such as Google Ads, Google Optimize, and Data Studio
        </p>
        <p>
          Need even more context? You can add LiveSession recordings as Google Analytics events.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Open recordings straight from the Analytics dashboard</li>
          <li>Access qualitative and quantitative data from one place</li>
          <li>Get more insights about your users</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Copy and paste a piece of code on your website. See a detailed tutorial in our{' '}
        <a href="/help/google-analytics-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(GoogleAnalytics);

GoogleAnalytics.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
